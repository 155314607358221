<template>
  <div class="legal-impressum legal-doc-view">
    <p><strong>Impressum</strong></p>
    <p><strong>Angaben gemäß §5 TMG ist:</strong><br>
      Individuallist UG (haftungsbeschränkt)<br>
      Mettmannerstraße 11<br>
      40233 Düsseldorf<br>
      Deutschland</p>

    <p><strong>Vertreten durch:</strong><br>
      Geschäftsführer Arkadij Bronstein</p>

    <p><strong>Kontakt:</strong><br>
      Telefon: 021197715857<br>
      E-Mail: <a href="mailto:info@individuallist.com" target="_blank">info@individuallist.com</a><br>
      Internet: <a href="http://www.individuallist.com/" target="_blank">www.individuallist.com</a></p>

    <p><strong>Registereintrag:</strong><br>
      Eintragung im Handelsregister: 10.05.2017<br>
      Registergericht: Amtsgericht Düsseldorf<br>
      Registernummer: HRB 80599</p>

    <p><strong>Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV:</strong><br>
      Arkadij Bronstein<br>
      Mettmannerstraße 11<br>
      40233 Düsseldorf<br>
      Deutschland</p>

    <p><strong>Finanzamt Düsseldorf-Mitte:</strong><br>
      Steuernummer: 133/5838/4124<br>
      Umsatzsteuer-Identifikationsnummer nach §27 a Umsatzsteuergesetz: DE313810367</p>

    <p><strong>Online-Streitbeilegung gemäß Art. 14 Abs. 1 ODR-VO:</strong><br>
      Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit, welche Sie unter <a href="http://ec.europa.eu/consumers/odr/" target="_blank">http://ec.europa.eu/consumers/odr/</a> finden.</p>

    <p>Zu einer Teilnahme an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle sind wir nicht verpflichtet, und bieten die Teilnahme an einem solchen Verfahren auch nicht an.</p>

    <p>Die Individuallist UG (haftungsbeschränkt) ist aber stets bemüht, eventuelle Meinungsverschiedenheiten mit unseren Kunden einvernehmlich beizulegen.</p>
  </div>
</template>
